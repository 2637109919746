<template>
  <div>
    <div class="row">
      <div class="col-8">
        <label class="label-chooser">{{ label }}</label>
        <file-chooser
          ref="fairImageFileChooser"
          @input="onLoadImageUrl"
          :options="{
            height: '110px',
            description: 'Haz click para cargar la imagen',
            regex: /\.(jpg|jpeg|png|gif)$/,
            error: 'Solo se permiten imagenes',
            fileSize: 2,
          }"
        ></file-chooser>
      </div>
      <div class="col-2 d-flex justify-center align-center mt-5">
        <div class="row">
          <div class="col-12 custom-col">
            <v-switch color="accent" class="ma-0"
              v-model="isActiveLocal"
              dense
              hide-details
              @click="onChangeStatus"
            ></v-switch>
          </div>
          <div class="col-12 custom-col" v-show="showTrash">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="onDeleteImage"
                >
                  mdi-trash-can-outline
                </v-icon>
              </template>
              <span>Eliminar Imagen</span>
            </v-tooltip>
          </div>
        </div>
      </div>
      <confirm-dialog
        :title="'Confirmación'"
        :description="appChangeStatusDialogDescription()"
        @onClose="closeChangeStatusModal()"
        @onConfirm="changeStatusFairAppModule()"
        :is-active="isConfirmChangeStatusActive"
      />
      <confirm-dialog
        :title="'Confirmación'"
        :description="appDeleteImageDialogDescription()"
        @onClose="closeDeleteIMageModal()"
        @onConfirm="deleteImage()"
        :is-active="isConfirmDeleteImage"
      />
    </div>
    <div class="row">
      <div class="col-8 d-flex flex-column">
        <h5>Titulo Interno: <span v-text="internalName"></span></h5>
        <h5>Titulo Externo: <span v-text="externalName"></span></h5>
      </div>
      <div class="col-2 px-0 ">
        <v-btn class="secondary black--text px-1 py-0" small @click="$emit('open-titles-modal')">Editar</v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import crudServiceMobileMixin from '@/mixins/crudServiceMobileMixin'
import notificationMixin from '@/mixins/notificationMixin'
import { APP_MODULES_URL, APP_MODULE_SAVE_IMAGE } from '@/constants/ServicesMobileConstants'
import FileChooser from '@/components/file-chooser/FileChooser'
import ConfirmDialog from '@/components/confirm-dialog/ConfirmDialog'

export default {
  name: 'FairAppModule',
  components: {
    FileChooser,
    ConfirmDialog
  },
  props: {
    id: {
      type: Number
    },
    imageUrl: {
      type: String,
      default () {
        return ''
      }
    },
    internalName: {
      type: String,
      default () {
        return ''
      }
    },
    externalName: {
      type: String,
      default () {
        return ''
      }
    },
    label: {
      type: String,
      default () {
        return ''
      }
    },
    isActive: {
      type: Number,
      default () {
        return 0
      }
    }
  },
  model: {
    prop: 'isActive',
    event: 'isActiveChange'
  },
  computed: {
    isActiveLocal: {
      get: function () {
        return Number(this.isActive)
      },
      set: function (value) {
        this.$emit('isActiveChange', Number(value))
      }
    }
  },
  data () {
    return {
      showTrash: false,
      isConfirmChangeStatusActive: false,
      isConfirmDeleteImage: false
    }
  },
  methods: {
    appChangeStatusDialogDescription () {
      return `¿Deseas ${(this.isActive ? 'activar' : 'desactivar')} el modulo ${this.label}?`
    },
    appDeleteImageDialogDescription () {
      return `¿Deseas eliminar la imagen del modulo ${this.label}?`
    },
    onLoadImageUrl (file) {
      if (file) {
        const formData = new FormData()
        formData.append('appModuleId', this.id)
        formData.append('appModuleImage', file)
        this.postMobile(APP_MODULE_SAVE_IMAGE, formData)
          .then((res) => {
            this.showTrash = true
            this.showSuccess(res.data.success)
          })
          .catch((err) => this.showError(err.response.data.error))
      }
    },
    onChangeStatus () {
      this.isConfirmChangeStatusActive = true
    },
    onDeleteImage () {
      this.isConfirmDeleteImage = true
    },
    changeStatusFairAppModule () {
      this.getMobile(APP_MODULES_URL + '/' + this.id + '/change-app-module')
        .then((res) => {
          this.isConfirmChangeStatusActive = false
          this.showSuccess(res.data.success)
        })
        .catch((err) => this.showError(err.response.data.error))
    },
    closeChangeStatusModal () {
      this.$emit('update-app-module-isActive', this.id, Number(!this.isActive))
      this.isConfirmChangeStatusActive = false
    },
    closeDeleteIMageModal () {
      this.isConfirmDeleteImage = false
    },
    deleteImage () {
      this.deleteMobile(APP_MODULE_SAVE_IMAGE, this.id)
        .then((res) => {
          this.$emit('delete-app-module-image', this.id)
          this.$refs.fairImageFileChooser.setImage(null)
          this.isConfirmDeleteImage = false
          this.showTrash = false
          this.showSuccess(res.data.success)
        })
        .catch((err) => this.showError(err.response.data.error))
    }
  },
  created () {
    this.$nextTick(() => {
      if (this.imageUrl) {
        this.showTrash = true
        this.$refs.fairImageFileChooser.setImage(this.imageUrl, true)
      }
    })
  },
  mixins: [
    crudServiceMobileMixin,
    notificationMixin
  ]
}
</script>
<style scoped>
  .custom-col{
    padding: 0px !important;
  }

  .label-chooser {
    font-weight: bold !important;
    font-size: 13px !important;
  }

  .image-input {
    background-repeat:no-repeat;
    background-size:contain;
    background-position:center;
  }
</style>
