<template>
  <fair-layout v-if="fair" :fair-id="fair.id" title="Configuración General">
    <template v-slot:banner>
      <banner
        v-if="fair"
        :title="fair.name"
        type="banner--admin"
        :options="{padding: '30px'}">
      </banner>
    </template>
    <template v-slot:content>
      <div class="px-7">
        <div class="row">
          <div class="col-12 col-xs-12 col-md-4 col-lg-3">
            <label class="label-chooser">Imagen principal de la Feria</label>
            <file-chooser
              ref="fairFileChooser"
              @input="onLoadFairImageUrl"
              :options="{
                height: '110px',
                description: 'Haz click para cargar la imagen',
                regex: /\.(jpg|jpeg|png|gif)$/,
                error: 'Solo se permiten imagenes',
                fileSize: 2,
              }"
            ></file-chooser>
          </div>
          <div class="col-12 col-xs-12 col-md-4 col-lg-3 d-flex justify-center">
            <div class="row">
              <div class="col-12 d-flex justify-center custom-col mt-4">
                <v-switch
                  color="accent"
                  class="custom-switch"
                  v-model="fair.isAppActive"
                  hide-details
                  dense
                  @click="onChangeStatusFairApp()"
                ></v-switch>
              </div>
              <div class="col-12 d-flex justify-center custom-col mb-16">
                <h3>¿APP Activa?</h3>
              </div>
            </div>
          </div>
          <div class="col-12 col-xs-12 col-md-4 col-lg-3 d-flex justify-center">
            <div class="row">
              <div class="col-12 d-flex justify-center custom-col mt-4">
                <v-switch color="accent" v-model="fair.expoAdminActive" dense hide-details
                  @click="onChangeStatusFairExpoAdmin()"
                ></v-switch>
              </div>
              <div class="col-12 d-flex justify-center custom-col mb-2">
                <h3>¿Admin Expo?</h3>
              </div>
              <div class="col-12 d-flex justify-center custom-col text-center">
                <p class="custom-p">Nota: Al inhabilitar el Admin expo también desactivas el acceso del expositor a charlas técnicas</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-10">
          <div class="col-12">
            <p>
              Los recursos de imágenes para las secciones deben cargarse en formato PNG o JPG,
              de dimensiones (1280px por 737px) y tamaño no superior a 100kbs.
            </p>
          </div>
        </div>
        <div class="row" v-if="appModules">
          <div
            v-for="appModule in appModules"
            :key="appModule.id"
            class="col-12 col-xs-12 col-md-4 col-lg-4"
          >
            <fair-app-module
              v-model="appModule.isActive"
              :id="appModule.id"
              :label="appModule.moduleDescription"
              :imageUrl="appModule.mainImageUrl"
              :externalName="appModule.externalName"
              :internalName="appModule.internalName"
              @update-app-module-isActive="updateAppModuleIsActive"
              @delete-app-module-image="deleteAppModuleImage"
              @open-titles-modal="$refs.sectionTitleModal.open(appModule)"
            ></fair-app-module>
          </div>
          <section-title-modal ref="sectionTitleModal" @set-titles="setSectionTitle"></section-title-modal>
        </div>
        <social-media :fair="fair"></social-media>
        <confirm-dialog
          :title="'Confirmación'"
          :description="`¿Deseas ${(Boolean(fair.isAppActive) ? 'activar' : 'desactivar')}  la app de la feria?`"
          @onClose="closeChangeStatusModal('isAppActive')"
          @onConfirm="changeStatusFairApp()"
          :is-active="isConfirmChangeStatusAppActive"
        />
        <confirm-dialog
          :title="'Confirmación'"
          :description="`¿Deseas ${(Boolean(fair.expoAdminActive) ? 'activar' : 'desactivar')}  el admin expo?`"
          @onClose="closeChangeStatusModal('expoAdminActive')"
          @onConfirm="changeStatusFairExpoAdmin()"
          :is-active="isConfirmChangeStatusExpoAdminActive"
        />
      </div>
    </template>
  </fair-layout>
</template>
<script>
import FairLayout from '@/layouts/FairLayout'
import Banner from '@/components/banner/Banner'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import crudServiceMobileMixin from '@/mixins/crudServiceMobileMixin'
import sessionMixin from '@/mixins/sessionMixin'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import ConfirmDialog from '@/components/confirm-dialog/ConfirmDialog'
import FileChooser from '@/components/file-chooser/FileChooser'
import { FAIRS_URL, FAIR_SAVE_IMAGE } from '@/constants/ServicesConstants'
import { APP_MODULES_URL } from '@/constants/ServicesMobileConstants'
import FairAppModule from './components/FairAppModule.vue'
import SocialMedia from './components/SocialMedia.vue'
import SectionTitleModal from './components/SectionTitleModal.vue'

export default {
  beforeRouteEnter (to, from, next) {
    const user = sessionMixin.methods.getUser()
    if (sessionMixin.methods.isSuperAdmin(user.roleId)) {
      next()
    } else {
      next({ path: '/error-403' })
    }
  },
  components: {
    FairLayout,
    Banner,
    ConfirmDialog,
    FileChooser,
    FairAppModule,
    SectionTitleModal,
    SocialMedia
  },
  data () {
    return {
      fair: null,
      appModules: null,
      isConfirmChangeStatusAppActive: false,
      isConfirmChangeStatusExpoAdminActive: false
    }
  },
  methods: {
    onLoadFairImageUrl (file) {
      if (file) {
        const formData = new FormData()
        formData.append('fairId', this.fair.id)
        formData.append('fairImage', file)
        this.post(FAIR_SAVE_IMAGE, formData)
          .then((res) => {
            this.showSuccess(res.data.success)
          })
          .catch((err) => this.showError(err.response.data.error))
      }
    },
    onChangeStatusFairApp () {
      this.isConfirmChangeStatusAppActive = true
    },
    onChangeStatusFairExpoAdmin () {
      this.isConfirmChangeStatusExpoAdminActive = true
    },
    changeStatusFairApp () {
      this.get(FAIRS_URL + '/' + this.fair.id + '/change-app-active')
        .then((res) => {
          this.isConfirmChangeStatusAppActive = false
          this.showSuccess(res.data.success)
        })
        .catch((err) => this.showError(err.response.data.error))
    },
    changeStatusFairExpoAdmin () {
      this.get(FAIRS_URL + '/' + this.fair.id + '/change-expo-admin-active')
        .then((res) => {
          this.isConfirmChangeStatusExpoAdminActive = false
          this.showSuccess(res.data.success)
        })
        .catch((err) => this.showError(err.response.data.error))
    },
    closeChangeStatusModal (modal) {
      if (modal === 'isAppActive') {
        this.isConfirmChangeStatusAppActive = false
        this.fair.isAppActive = !this.fair.isAppActive
      } else {
        this.isConfirmChangeStatusExpoAdminActive = false
        this.fair.expoAdminActive = !this.fair.expoAdminActive
      }
    },
    async getFair () {
      const response = await this.get(FAIRS_URL + '/' + this.$route.params.fairId)
      this.fair = response.data
      this.getAppModules()
      this.$nextTick(() => {
        if (this.fair.imageUrl) {
          this.$refs.fairFileChooser.setImage(this.fair.imageUrl)
        }
      })
    },
    getAppModules () {
      this.getMobile(APP_MODULES_URL + '/' + this.$route.params.fairId)
        .then((response) => {
          this.appModules = response.data
        })
    },
    updateAppModuleIsActive (appModuleId, newValue) {
      const index = this.appModules.findIndex((appModule) => appModule.id === appModuleId)
      this.appModules[index].isActive = newValue
    },
    deleteAppModuleImage (appModuleId) {
      const index = this.appModules.findIndex((appModule) => appModule.id === appModuleId)
      this.appModules[index].mainImageUrl = null
    },
    setSectionTitle (appModule) {
      if (this.appModules) {
        const item = this.appModules.filter(el => el.id === appModule.id)[0]
        if (item) {
          item.internalName = appModule.internalName
          item.externalName = appModule.externalName
        }
      }
    }
  },
  async created () {
    try {
      this.showLoader()
      await this.getFair()
      this.hideLoader()
    } catch {
      this.hideLoader()
      this.showError()
    }
  },
  mixins: [
    crudServiceMixin,
    crudServiceMobileMixin,
    loaderMixin,
    notificationMixin
  ],
  name: 'GeneralSettings'
}
</script>
<style scoped>
  .custom-col {
    padding: 0px !important;
  }

  .custom-p {
    font-size: 13px !important;
  }

  .label-chooser {
    font-weight: bold !important;
    font-size: 13px !important;
  }

  .image-input {
    background-repeat:no-repeat;
    background-size:contain;
    background-position:center;
  }
</style>
